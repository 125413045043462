var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-card", [
    _c(
      "div",
      { staticClass: "step_wrapper" },
      [
        _c(
          "a-steps",
          {
            staticClass: "report_steps",
            attrs: { current: _vm.current, labelPlacement: "vertical" },
          },
          [
            _c("a-step", { attrs: { title: "确认数据可用性" } }),
            _c("a-step", { attrs: { title: "专家制作报告" } }),
            _c("a-step", { attrs: { title: "完成" } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }