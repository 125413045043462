<!--
 * @Description: 流程环节
-->
<template>
  <a-card>
    <div class="step_wrapper">
      <a-steps class="report_steps" :current="current" labelPlacement="vertical">
        <a-step title="确认数据可用性"></a-step>
        <a-step title="专家制作报告" />
        <a-step title="完成" />
      </a-steps>
    </div>
  </a-card>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: 'DETECTING'
    }
  },
  data() {
    return {}
  },
  computed: {
    current() {
      const statusList = [
        ['DETECTING', 'DETECTED', 'UPLOADED', 'AVAILABLE_CONFIRMING', 'AVAILABLE_REJECTED'],
        ['REPORTING', 'REPORT_REJECTED', 'REPORTED'],
        ['COMPLETE']
      ]
      return statusList.findIndex((list) => list.includes(this.status))
    }
  }
}
</script>

<style lang="scss" scoped>
.step_wrapper {
  display: flex;
  justify-content: center;
}

.report_steps {
  width: 62%;
}
</style>
