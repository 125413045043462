var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    { staticClass: "analysis_detail_wrapper" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c("AnalysisSteps", { attrs: { status: _vm.status } }),
          _c("div", { staticClass: "mt-10" }),
          _c("BasicInfo", { attrs: { info: _vm.originData } }),
          _c("div", { staticClass: "mt-10" }),
          _c("DataList", { attrs: { info: _vm.originData } }, [
            _c(
              "div",
              { staticClass: "footer_btn" },
              [
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }