var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "base_info", attrs: { title: "基础信息" } },
    [
      _c("ul", { staticClass: "info_list" }, [
        _c("li", { staticClass: "info_item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("项目名称：")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.info.projectName || "-")),
          ]),
        ]),
        _c("li", { staticClass: "info_item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("地址：")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.info.address || "-")),
          ]),
        ]),
        _c("li", { staticClass: "info_item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("请求分析时间：")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.dateFormat(_vm.info.analyseTime))),
          ]),
        ]),
        _c("li", { staticClass: "info_item desc_item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("项目描述：")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.info.description || "-")),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }