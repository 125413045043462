<!--
 * @Description: 文件描述
-->
<template>
  <page-header-wrapper class="analysis_detail_wrapper">
    <a-spin :spinning="loading">
      <AnalysisSteps :status="status"></AnalysisSteps>

      <div class="mt-10"></div>

      <BasicInfo :info="originData"></BasicInfo>

      <div class="mt-10"></div>

      <DataList :info="originData">
        <div class="footer_btn">
          <a-button type="primary" @click="$router.go(-1)">返回</a-button>
        </div>
      </DataList>
    </a-spin>
  </page-header-wrapper>
</template>

<script>
import AnalysisSteps from './components/AnalysisSteps.vue'
import BasicInfo from './components/BasicInfo.vue'
import DataList from '../submit-application/components/DataList.vue'
import { viewReportDetail } from '../api/functionApi'

export default {
  name: 'AnalysisDetail',
  components: {
    AnalysisSteps,
    BasicInfo,
    DataList
  },
  data() {
    return {
      // 初始数据，不是表单，可以不列举每一个key
      loading: false,
      originData: {}
    }
  },
  computed: {
    status() {
      return this.originData.status
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const params = { id: this.$route.params.id }
        const data = await viewReportDetail(params)
        this.originData = data || {}
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer_btn {
  text-align: right;
}
</style>
