<!--
 * @Description: 文件描述
-->
<template>
  <a-card class="base_info" title="基础信息">
    <ul class="info_list">
      <li class="info_item">
        <span class="label">项目名称：</span>
        <span class="value">{{ info.projectName || '-' }}</span>
      </li>
      <li class="info_item">
        <span class="label">地址：</span>
        <span class="value">{{ info.address || '-' }}</span>
      </li>

      <li class="info_item">
        <span class="label">请求分析时间：</span>
        <span class="value">{{ dateFormat(info.analyseTime) }}</span>
      </li>

      <li class="info_item desc_item">
        <span class="label">项目描述：</span>
        <span class="value">{{ info.description || '-' }}</span>
      </li>
    </ul>
  </a-card>
</template>

<script>
import { formatTime } from '@/utils/date'

export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  methods: {
    dateFormat(val) {
      return formatTime(val, 'YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
// 信息列表样式
.info_list {
  display: flex;
  flex-wrap: wrap;
  .info_item {
    width: 50%;
    margin-bottom: 10px;
    display: flex;
    .label {
      font-weight: bold;
    }
    .value {
      flex: 1;
    }
    &.desc_item {
      width: 100%;
    }
  }
}
</style>
